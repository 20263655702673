// $font-path: 'assets/fonts/';
// @font-face {
//   font-family: 'Avenir Next Condensed Demi';
//   src: url('#{$font-path}');
//   src: url('#{$font-path}?#iefix') format('embedded-opentype'),
//     url('#{$font-path}AvenirNextCondensed-DemiBoldItalic.woff2') format('woff2'),
//     url('#{$font-path}AvenirNextCondensed-DemiBoldItalic.woff') format('woff'),
//     url('#{$font-path}AvenirNextCondensed-DemiBoldItalic.ttf')
//       format('truetype'),
//     url('#{$font-path}AvenirNextCondensed-DemiBoldItalic.svg#AvenirNextCondensed-DemiBoldItalic')
//       format('svg');
//   font-weight: 600;
//   font-style: italic;
//   font-display: swap;
// }

// @font-face {
//   font-family: 'Avenir Next Condensed';
//   src: url('#{$font-path}');
//   src: url('#{$font-path}?#iefix') format('embedded-opentype'),
//     url('#{$font-path}AvenirNextCondensed-Italic.woff2') format('woff2'),
//     url('#{$font-path}AvenirNextCondensed-Italic.woff') format('woff'),
//     url('#{$font-path}AvenirNextCondensed-Italic.ttf') format('truetype'),
//     url('#{$font-path}AvenirNextCondensed-Italic.svg#AvenirNextCondensed-Italic')
//       format('svg');
//   font-weight: normal;
//   font-style: italic;
//   font-display: swap;
// }

// @font-face {
//   font-family: 'Avenir Next Condensed';
//   src: url('#{$font-path}AvenirNextCondensed-MediumItalic.eot');
//   src: url('#{$font-path}AvenirNextCondensed-MediumItalic.eot?#iefix')
//       format('embedded-opentype'),
//     url('#{$font-path}AvenirNextCondensed-MediumItalic.woff2') format('woff2'),
//     url('#{$font-path}AvenirNextCondensed-MediumItalic.woff') format('woff'),
//     url('#{$font-path}AvenirNextCondensed-MediumItalic.ttf') format('truetype'),
//     url('#{$font-path}AvenirNextCondensed-MediumItalic.svg#AvenirNextCondensed-MediumItalic')
//       format('svg');
//   font-weight: 500;
//   font-style: italic;
//   font-display: swap;
// }

body {
  font-family: 'Avenir Next Condensed' sans-serif;
  font-weight: normal;
  font-style: italic;
  background: #000;
  font-size: 12px;
  color: #fff;
  position: relative;
  overflow-x: hidden;
}

h2 {
  font-size: 22px;
  font-style: italic;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 30px;
  margin: 20px 0;
}

a {
  color: $primary;
  text-decoration: none;

  &:hover,
  &:active,
  &:focus {
    text-decoration: none;
  }
}

.top-bar {
  position: sticky;
  top: 0;
  z-index: 10;
  transform: translateY(-1px);
  display: none;

  img {
    display: block;
    max-width: 100%;
  }
}
