.btn {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  padding: 10px 15px;
  font-size: 14px;
  line-height: 1.5;
  transition: all 0.3s ease-in-out;
  backface-visibility: hidden;
  cursor: pointer;
  text-transform: uppercase;
  border: none;
  letter-spacing: 0.08em;
  border-radius: 4px;
  font-weight: bold;

  @include hover-focus-active{
    outline: none;
    transform: translateY(-1px);
  }

  &-white{
    background: #fff;
    color: $gray-600;

    @include hover-focus-active{
      background: $gray-600;
      box-shadow: 0px 4px 4px rgba( $gray-600, 0.4 );
      color: $white;
    }
  }
}

.btn-rounded{
  border-radius: 50%;
}

@each $color, $value in $theme-colors {
  .btn-#{$color}{
    color: #fff;
    background: $value;

    @include hover-focus-active{
      background: darken( $value , 2);
      box-shadow: 0px 4px 4px rgba( $value,0.4 );
      color: #fff;
    }
  }
}

@each $color, $value in $theme-colors {
  .btn-#{$color}-outline{
    color: $value;
    background: #fff;

    @include hover-focus-active{
      background: $value;
      color: #fff;
      box-shadow: 0px 4px 4px rgba( $value,0.4 );
    }
  }
}

@each $color, $value in $theme-colors {
  .btn-#{$color}-light{
    color: $value;
    background: rgba( $value, 0.15);

    @include hover-focus-active{
      background: $value;
      color: #fff;
      box-shadow: 0px 4px 4px rgba( $value,0.4 );
    }
  }
}

.btn-group-lg>.btn, .btn-lg{
  padding: 14px 25px;
  line-height: 1.5;
}

.btn-group-sm>.btn, .btn-sm {
  padding: .25rem .5rem;
  line-height: 1.5;
}

.play-btn{
  display: flex;
  align-items: center;
  color: #000;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 0.1em;
  font-size: 14px;

  svg{
    max-width: 24px;
    margin-right: 10px;
    transition: all 0.3s ease-in-out;
  }

  &:hover{
    color: $primary;

    svg{
      fill: $primary
    }
  }
}