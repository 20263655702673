// stylelint-disable declaration-no-important

// Common values
@each $position in $positions {
  .position-#{$position} { position: $position !important; }
}

// Shorthand

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 10001;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10001;
}

.sticky-top {
  @supports (position: sticky) {
    position: sticky;
    top: 0;
    z-index: 10001;
  }
}

.p-top{
  top: 0;
}

.bottom{
  bottom: 0;
}

.right{
  right: 0;
}

.left{
  left: 0;
}



