// Headings

  h1, h2, h3, h4, h5, h6,
  .h1, .h2, .h3, .h4, .h5, .h6 {
    margin-bottom: $headings-margin-bottom;
    font-family: $headings-font-family;
    font-weight: $headings-font-weight;
    line-height: $headings-line-height;
    color: $headings-color;
    letter-spacing: 0.04em;
  }

  h1, .h1 { font-size: $h1-font-size; }
  h2, .h2 { font-size: $h2-font-size; }
  h3, .h3 { font-size: $h3-font-size; }
  h4, .h4 { font-size: $h4-font-size; }
  h5, .h5 { font-size: $h5-font-size; }
  h6, .h6 { font-size: $h6-font-size; }

  .lead {
    font-size: $lead-font-size;
    font-weight: $lead-font-weight;
  }

// Type display classes

  .display-1 {
    font-size: $display1-size;
    font-weight: $display1-weight;
    line-height: $display-line-height;
  }
  .display-2 {
    font-size: $display2-size;
    font-weight: $display2-weight;
    line-height: $display-line-height;
  }
  .display-3 {
    font-size: $display3-size;
    font-weight: $display3-weight;
    line-height: $display-line-height;
  }
  .display-4 {
    font-size: $display4-size;
    font-weight: $display4-weight;
    line-height: $display-line-height;
  }

// Horizontal rules

  hr {
    margin-top: $hr-margin-y;
    margin-bottom: $hr-margin-y;
    border: 0;
    border-top: $hr-border-width solid $hr-border-color;
  }

// Emphasis

  small,
  .small {
    font-size: $small-font-size;
    font-weight: $font-weight-normal;
  }

  mark,
  .mark {
    padding: $mark-padding;
    background-color: $mark-bg;
  }

// Text

  .text-monospace { font-family: $font-family-monospace; }

// Alignment

  .text-justify  { text-align: justify !important; }
  .text-nowrap   { white-space: nowrap !important; }
  .text-truncate { @include text-truncate; }

// Responsive alignment

  @each $breakpoint in map-keys($grid-breakpoints) {
    @include media-breakpoint-up($breakpoint) {
      $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

      .text#{$infix}-left   { text-align: left !important; }
      .text#{$infix}-right  { text-align: right !important; }
      .text#{$infix}-center { text-align: center !important; }
    }
  }

// Transformation

  .text-lowercase  { text-transform: lowercase !important; }
  .text-uppercase  { text-transform: uppercase !important; }
  .text-capitalize { text-transform: capitalize !important; }

// Weight and italics

  .fw-200     { font-weight: 200 !important; }
  .fw-300           { font-weight: 300 !important; }
  .fw-400          { font-weight: 400 !important; }
  .fw-500          { font-weight: 500 !important; }
  .fw-600       { font-weight: 600 !important; }
  .fw-700            { font-weight: 700 !important; }
  .font-italic        { font-style: italic !important; }

// Contextual colors

  .text-white { color: $white !important; }

  @each $color, $value in $theme-colors {
    @include text-emphasis-variant(".text-#{$color}", $value);

    .text-#{$color} a {
      color: $value !important;

      @include hover-focus-active{
        color: darken($value, 8) !important;
      }
    }
  }

  .text-body { color: $body-color !important; }
  .text-muted { color: $text-muted !important; }

  .text-black-50 { color: rgba($black, .5) !important; }
  .text-white-50 { color: rgba($white, .5) !important; }

// Misc

  .text-hide {
    @include text-hide($ignore-warning: true);
  }

// Fill Color

  @each $color, $value in $theme-colors {
    .fill-#{$color} {
      fill: $value !important;
    }
  }

// Deco

  .deco{
    position: relative;
    padding-bottom: 30px;

    span{
      position: relative;
      
      &:before{
        content:"";
        position: absolute;
        bottom: -15px;
        width: 100%;
        height: 2px;
        background: #1E3560;
        background: -webkit-linear-gradient(to right, #fff 0%,  #D61819 50%, #fff 100%);
        background: -moz-linear-gradient(to right, #fff 0%, #D61819 50%, #fff 100%);
        background: linear-gradient(to right, #fff 0%, #D61819 50%, #fff 100%);
      }
    }
  }