// Container widths

  @if $enable-grid-classes {
    .container {
      @include make-container();
      @include make-container-max-widths();
    }
  }

// Fluid container

  @if $enable-grid-classes {
    .container-fluid {
      @include make-container();
    }
  }

// Row

  @if $enable-grid-classes {
    .row {
      @include make-row();
    }

    // Remove the negative margin from default .row, then the horizontal padding
    // from all immediate children columns (to prevent runaway style inheritance).
    .no-gutters {
      margin-right: 0;
      margin-left: 0;

      > .col,
      > [class*="col-"] {
        padding-right: 0;
        padding-left: 0;
      }
    }
  }

// Columns

  @if $enable-grid-classes {
    @include make-grid-columns();
  }