// Functions
@import "functions";

// Variables
@import "variables";

// Mixins
@import "mixins";

// Reset
@import "utilities/reset";

// Grid

@import "utilities/flex";
@import "grid";

@import "~bootstrap/scss/bootstrap";
// Utilities
@import "utilities/btn";
@import "utilities/borders";
@import "utilities/card";
@import "utilities/displays";
@import "utilities/float";
@import "utilities/media";
@import "utilities/overflow";
@import "utilities/positions";
@import "utilities/shadows";
@import "utilities/sizing";
@import "utilities/spacing";
@import "utilities/text";
@import "utilities/utils";

// Plugins
@import "swiper";
@import "freakflags";

// Layout
@import "base";
@import "layout";

.player {
  img {
    border-radius: 100%;
  }
}
.accordion-item {
  border-radius: 0px;
  background-color: #000;
}
.accordion-header {
  margin-top: 0px !important;
  background-color: #000 !important;
  line-height: 0px !important;
  margin-bottom: 10px !important;

  color: inherit;
  letter-spacing: 0.04em;
}
.accordion-body {
  background-color: #000 !important;
  padding: 0px;
}

.accordion-item:first-of-type .accordion-button {
  border-radius: 0px !important;
  font-size: 16px !important;
  font-weight: 500;
  line-height: 1.2;
  padding: 10px;
}

.accordion-button:not(.collapsed) {
  color: #fff;
  background-color: #333;
  box-shadow: none;
}

.accordion-item:first-of-type .accordion-button {
  border-radius: 0px !important;
}

.accordion-button:focus {
  outline: 0 !important;
  box-shadow: none !important;
}

.accordion-item:last-of-type .accordion-button.collapsed {
  border-radius: 0px;
  background: #333;
  color: #fff;
}

.team-switcher a,
.team-switcher a.active,
.team-switcher a:hover {
  color: #fff;
}

.btn:focus {
  outline: 0;
  box-shadow: none !important;
}

.accordion-custom-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.accordion-custom-header-desc {
  font-family: "Avenir Next Condensed";
  font-weight: normal;
  font-style: italic;
  font-size: 12px;
  color: #fff;
  position: relative;
  right: 10px;
  top: 5px;
}
