// Base

body{
  background: #000;
}

a{
  color: #fff;

  &:hover, &:focus, &:active{
    color: #fff;
    outline: none;
  }
}

// Empty state

.empty-state{
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 60vh;

  &-content{
    text-align: center;
    padding: 0 30px;

    img{
      margin: 0 auto 20px;
      display: block;
      opacity: 0.3;
      max-width: 95px;
    }

    h4{
      opacity: 0.6;
      font-size: 22px;
      margin-top: 30px;
      margin-bottom: 15px;
    }

    p{
      font-size: 16px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 26px;
      opacity: 0.4;
    }

    a.btn{
      display: block;
      border: solid 1px #fff;
      margin-top: 30px;
      max-width: 150px;
      margin: 30px auto 0;
      padding: 8px 16px;
    }
  }
}

// Loader

.loading{
  max-width: 100%;
  margin: 0 auto;
  height: 80vh;
  display: flex; 
  align-items: center;
  position: relative;

  &:before{
    content: "Please wait...";
    position: absolute;
    display: block;
    width: 100%;
    text-align: center;
    transform: translateY(-30px);
  }

  .dots {
    position: relative;
    width: 100%;
    animation: move-2 3s linear infinite;
    animation-delay: -400ms;
    max-width: 100px;
    margin: 0 auto;
  }

  .dot {
    margin: 0 5px;
    display: inline-block;
    border-radius: 50%;
    width: 0.5rem;
    height: 0.5rem;
    background: #CACACA;
    animation: move 3s cubic-bezier(0.1, 0.8, 0.9, 0.1) infinite;

    &:nth-of-type(2) {
      animation-delay: -100ms;
    }

    &:nth-of-type(3) {
      animation-delay: -200ms;
    }

    &:nth-of-type(4) {
      animation-delay: -300ms;
    }

    &:nth-of-type(5) {
      animation-delay: -400ms;
    }
  }
}

@-webkit-keyframes move {
  0% {
    transform: translateX(-10em);
    opacity: 0;
  }
  10%, 70% {
    opacity: 0.9;
  }
  80%, 100% {
    transform: translateX(10em);
    opacity: 0;
  }
}

@keyframes move {
  0% {
    transform: translateX(-10em);
    opacity: 0;
  }
  10%, 70% {
    opacity: 0.9;
  }
  80%, 100% {
    transform: translateX(10em);
    opacity: 0;
  }
}

@-webkit-keyframes move-2 {
  0% {
    transform: translateX(4em);
  }
  100% {
    transform: translateX(-4em);
  }
}

@keyframes move-2 {
  0% {
    transform: translateX(4em);
  }
  100% {
    transform: translateX(-4em);
  }
}


// Tabs

  .tabs{
    display: flex;
    border-bottom: solid 1px $gray-900;

    a{
      width: 33.33%;
      text-align: center;
      padding: 15px 0;
      position: relative;
      font-weight: 500;
      letter-spacing: 0.04em;
      font-size: 13px;
      text-transform: uppercase;

      &:before{
        content:"";
        display: block;
        position: absolute;
        height: 2px;
        width: 100%;
        background: $primary;
        bottom: 0;
        left: 0;
        transform: scaleX(0);
        transform-origin: center center;
        transition: all 0.3s linear;
        opacity: 0;
        z-index: 2;
      }

      &:active, &:hover, &:focus{
        color: #fff;
        text-decoration: none;
        outline: none;
      }

      &.active{
        color: #fff;

        &:before{
          opacity: 1;
          transform: scaleX(1);
        }
      }
    }
  }

  .tab-content{
    display: none;
    padding: 20px 0;

    &.active{
      display: block;
    }
  }

// Match card


.match-card{
  background: #0D0D0D;
  padding: 10px;
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 20px;

  &-title{
    background: #323232;
    margin: -10px -10px 0;
    // padding: 10px;
    // padding-right: 15px;
    padding: 7px 15px 7px 10px;
    display: flex;
    align-items: center;

    h3{
      margin-bottom: 0;
      font-size: 13px;
      font-weight: 600;
    }

    .status{
      color: #fff;
      font-size: 10px;
      span{
        position: relative;
        padding-left: 16px;
        font-weight: 500;
        text-transform: uppercase;

        &:before{
          content:"";
          width: 8px;
          height: 8px;
          border-radius: 10px;
          background: #fff;
          position: absolute;
          left: 0;
          top: 0;
          bottom: 0;
          margin: auto;
        }

        &.live:before{
          background: $success;
        }

        &.upcoming:before{
          background: $warning;
        }

        &.completed:before{
          background: #F13130;
        }
      }
    }
  }

  &-meta{
    padding: 10px 0;

    span{
      position: relative;
      padding-right: 15px;
      margin-right: 10px;
      color: #fff;
      opacity: 0.5;

      &:after{
        content:"";
        position: absolute;
        width: 5px;
        height: 5px;
        border-radius: 5px;
        background: #fff;
        right: 0;
        top: 0; bottom: 0;
        margin: auto;
      }

      &:last-child{
        margin-right: 0;
        padding-right: 0;

        &:after{
          display: none;
        }
      }
    }
  }

  &-teams{
    padding-bottom: 10px;
    .team-name{
      font-size: 15px;
    }

    .score{
      // font-size: 18px;
      // font-weight: bold;
      .run{
        font-size: 18px;
        font-weight: bold;
      }
      .over{
        font-size: 13px;
        font-weight: normal;
        padding-left: 2px;
      }
    }
    .yet-to-bat{
      font-size: 13px;
    }
  }

  &-status{
    color: $primary;
    font-weight: 600;
    font-size: 13px;
    letter-spacing: 0.02em;
  }
}

// Match Info

.info-list{
  padding: 0;
  margin: 0 0 25px;
  list-style: none;

  &.match-detail li{

    span{
      width: auto;
      text-align: right;
    }
  }

  &.fall-of-wicket span{
    opacity: 0.4; 
    display: inline-block;
    width: auto;
  }

  li{
    border-bottom: solid 1px $gray-800;
    padding: 10px;
    // margin-bottom: 10px;
    display: flex;
    align-items: start;
    background: rgba(#fff, 0.08);

    &.last span{ width: auto; }
    
    &:last-child{
      border-bottom: none;
    }

    &.header{

      h4{
        opacity: 0.5;
      }

      span, h4{
        font-weight: bold;
      }
    }

    h4{
      font-size: 13px;
      margin: 0;

      span{
        display: block;
        width: 100px;
        font-size: 10px;
        opacity: 0.4;
        margin-top: 0;
      }
    }

    font-size: 13px;
    color: #fff;
    
    span{
      text-align: left;
      width: 35px;
      font-size: 12px;
    }
  }
}

.accordion{

  &-title{
    background: rgba(#fff, 0.2);
    display: flex;
    align-items: center;
    padding: 10px;
    padding-right: 40px;
    position: relative;
    margin-bottom: 10px;
    cursor: pointer;

    &:before{
      content:"";
      display: block;
      width: 8px;
      height: 8px;
      position: absolute;
      right: 12px;
      top: 0; bottom: 0;
      margin: auto;
      border: solid 2px #fff;
      // transform: rotate(-135deg) translate(-2px, 3px);
      transform: rotate(-135deg) translate(5px);
      border-bottom: none;
      border-right: none;
      // transition: all 0.3s ease-in-out;
    }

    h4{
      margin: 0;
      font-size: 16px;
    }

    &.active:before{
      transform: rotate(45deg) translate(-2px, 3px);
      // transform: rotate(45deg) translate(5px);
    }
  }

  &-content{
    display: none;

    &.show{
      display: block;
    }
  }
}

.team-switcher{
  text-align: center;
  margin: 0 0 15px;
  display: flex;
  align-items: center;
  position: relative;
  background: $gray-900;

  a{
    background: transparent;
    padding: 6px 12px;
    border-radius: 0;
    width: 50%;
    position: relative;
    z-index: 2;
    font-size: 13px;

    &:hover, &:active, &:focus{
      background: transparent;
      outline: none;
      transform: translateY(0px);
    }

    &.active{
      background: transparent;
      transform: translateY(0px);
    }
  }

  a[rel=team-one].active ~ span {
    left: 0;
  }

  a[rel=team-two].active ~ span {
    left: 50%
  }

  span{
    position: absolute;
    width: 50%;
    height: 100%;
    background: $primary;
    left: 0;
    top: 0;
    transition: all 0.3s ease-in-out;
  }
}

.team{
  display: none;

  &.active{
    display: block;
  }
}

// player-list

h2.title{
  font-size: 17px;
  font-weight: 500;
  line-height: 23px;
  text-transform: uppercase;
}

.player-list{
  margin: 0;
  padding: 0;
  list-style: none;

  li{
    display: flex;
    align-items: center;
    position: relative;
    padding: 10px 0 8px;
    margin-bottom: 10px;
    
    img{
      max-width: 32px;
    }

    h4{
      margin: 0;
      line-height: 1;
      margin-left: 15px;
      position: relative;
      width: 100%;
      font-size: 15px;

      &:before{
        content:"";
        position: absolute;
        width: 100%;
        height: 1px;
        background: $gray-900;
        bottom: -15px;
      }

      span{
        display: block;
        font-size: 13px;
        opacity: 0.4;
        font-weight: normal;
        margin-top: 5px;
      }
    }
  }
}

.sticky{
  position: sticky;
  background: #000;
  top: 0;
  z-index: 999;
}

.load-more{
  text-align: center;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;

  i{
    width: 20px;
    margin-right: 10px;
    position: relative;
    top: 3px;
  }

  .spinner{
    animation: spin 1s linear infinite;
  }

  @keyframes spin {
    to{
      transform: rotate(360deg);
    }
  }

  &.complete{
    text-align: center;
    opacity: 0.6;
    display: block;

    img{
      max-width: 60px;
    }

    p{
      margin-top: 15px;
      font-size: 18px;
    }

    span{
      display: block;
      font-size: 13px;
      font-weight: normal;
    }
  }
}